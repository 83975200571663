import styled, { themeGet, device } from "@styled";
import { animateUpDown } from "@assets/css/animations";

export const PublishingWrapper = styled.section`
  padding: 90px 0;
`;
export const PublishingInner = styled.section``;
export const PublishingImgwrap = styled.div`
  margin: 0 auto;
  padding: 0;
  position: relative;
  text-align: center;
  max-width: 300px;
  ${device.small} {
    padding: 40px;
    max-width: inherit;
  }
`;

export const PublishingImgMiddle = styled.div``;
export const PublishingImgFloatingWrap = styled.div`
  max-width: 200px;
  margin: 0 auto;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${device.small} {
    max-width: 350px;
  }
  ${device.medium} {
    max-width: 500px;
  }
  ${device.large} {
    max-width: 760px;
  }
`;
export const PublishingImgFloating = styled.div`
    height: 40px;
    width: 40px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 20%);
    background: #fff;
    position: absolute;
    cursor: pointer;
    overflow: hidden;
    transition: ${themeGet("transition")};
    ${device.small} {
        height: 60px;
        width: 60px;
        padding: 15px;
      }
      ${device.medium} {
        height: 80px;
        width: 80px;
      }
    ${device.large} {
        height: 104px;
        width: 104px;
      }
    &:hover {
        box-shadow: 0 0 15px 0 rgb(0 0 0 / 30%);
        transform: translateY(-3px);
    }
    
    &.img-1 {
        top: -10px;
        left: -40px;
        ${device.small} {
            top: 15px;
            left: -30px;
          }
        ${device.medium} {
            top: 0;
            left: -35px;
          }
    }
    &.img-2 {
        top: calc(50% - 35px);
        left: -65px;
        ${device.small} {
            top: calc(50% - 65px);
            left: -60px;
          }
        ${device.medium} {
            top: calc(50% - 95px);
            left: -75px;
          }
    }
    &.img-3 {
        bottom: 25px;
        left: -40px;
        ${device.small} {
            bottom: 85px;
            left: -30px;
          }
        ${device.medium} {
            bottom: 110px;
            left: -35px;
          }
    }
    &.img-4 {
        top: -10px;
        right: -40px;
        ${device.small} {
            top: 15px;
            right: -30px;
          }
        ${device.medium} {
            top: 0;
            right: -35px;
          }
    }
    &.img-5 {
        top: calc(50% - 35px);
        right: -65px;
        ${device.small} {
            top: calc(50% - 65px);
            right: -60px;
        }
        ${device.medium} {
            top: calc(50% - 95px);
            right: -75px;
        }
    }
    &.img-6 {
        bottom: 25px;
        right: -40px;
        ${device.small} {
            bottom: 85px;
            right: -30px;
          }
        ${device.medium} {
            bottom: 110px;
            right: -35px;
          }
    }
    &.img-7 {
        bottom: 0;
        ${device.small} {
            bottom: 40px;
          }
    }
    &.animation_images {
        animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
        animation-name: ${animateUpDown};
        animation-iteration-count: infinite;
        &.one {
          animation-duration: 3.2s;
        }
        &.two {
          animation-duration: 3.8s;
        }
        &.three {
          animation-duration: 2.8s;
        }
      }
}
`;
