import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import SectionTitle from "@ui/section-title";
import Image from "@ui/image";

import {
  PublishingWrapper,
  PublishingInner,
  PublishingImgwrap,
  PublishingImgFloatingWrap,
  PublishingImgMiddle,
  PublishingImgFloating,
} from "./style";
const ELPublishing = ({ data }) => {
  return (
    <PublishingWrapper id={data.section}>
      <PublishingInner>
        <Container>
          <Row>
            <Col lg={12}>
              <SectionTitle
                title={data.section_title?.title}
                subtitle={data.section_title?.subtitle}
                description={data.section_title?.description}
              />
            </Col>
            <Col lg={12}></Col>
          </Row>

          <Row className="mt-5 accordionRow">
            <Col lg={12}>
              <PublishingImgwrap>
                <PublishingImgMiddle>
                  <Image
                    src={data.images[0]?.src}
                    alt={data.images[0]?.alt || "Icon"}
                    title={data.images[0]?.alt}
                  />
                </PublishingImgMiddle>
                <PublishingImgFloatingWrap>
                { data.images[1] &&
                  <PublishingImgFloating className="img-1 animation_images one">
                    <Image
                      src={data.images[1]?.src}
                      alt={data.images[1]?.alt || "Icon"}
                      title={data.images[1]?.alt}
                    />
                  </PublishingImgFloating>
                  }

                  { data.images[2] &&
                  <PublishingImgFloating className="img-2 animation_images two">
                    <Image
                      src={data.images[2]?.src}
                      alt={data.images[2]?.alt || "Icon"}
                      title={data.images[2]?.alt}
                    />
                  </PublishingImgFloating>
                  }

                  { data.images[3] &&
                  <PublishingImgFloating className="img-3 animation_images three">
                    <Image
                      src={data.images[3]?.src}
                      alt={data.images[3]?.alt || "Icon"}
                      title={data.images[3]?.alt}
                    />
                  </PublishingImgFloating>
                  }

                  { data.images[4] &&
                  <PublishingImgFloating className="img-4 animation_images one">
                    <Image
                      src={data.images[4]?.src}
                      alt={data.images[4]?.alt || "Icon"}
                      title={data.images[4]?.alt}
                    />
                  </PublishingImgFloating>
                  }

                  { data.images[5] &&
                  <PublishingImgFloating className="img-5 animation_images two">
                    <Image
                      src={data.images[5]?.src}
                      alt={data.images[5]?.alt || "Icon"}
                      title={data.images[5]?.alt}
                    />
                  </PublishingImgFloating>
                  }

                  { data.images[6] &&
                  <PublishingImgFloating className="img-6 animation_images three">
                    <Image
                      src={data.images[6]?.src}
                      alt={data.images[6]?.alt || "Icon"}
                      title={data.images[6]?.alt}
                    />
                  </PublishingImgFloating>
                  }

                  { data.images[7] &&
                  <PublishingImgFloating className="img-7 animation_images one">
                    <Image
                      src={data.images[7]?.src}
                      alt={data.images[7]?.alt || "Icon"}
                      title={data.images[7]?.alt}
                    />
                  </PublishingImgFloating>
                  }
                </PublishingImgFloatingWrap>
              </PublishingImgwrap>
            </Col>
          </Row>
        </Container>
      </PublishingInner>
    </PublishingWrapper>
  );
};

ELPublishing.propTypes = {
  SectionTitleStyle: PropTypes.object,
  headingStyle: PropTypes.object,
};
ELPublishing.defaultProps = {
  SectionTitleStyle: {
  },
  headingStyle: {
    as: "h4",
  },
};

export default ELPublishing;
