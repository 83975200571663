import React, { Suspense, lazy, useState } from 'react';
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
// import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import FeatureGrid from "@components/feature-grid";
import PartnerArea from "@containers/partner/layout-04";
import IntroArea from "@components/introarea/layout-1";
import BoxSectionThree from "@components/BoxSection/layout-three/layout-2";
import ELPublishing from "@containers/elearning/elearning-publishing";
import FaqArea from "@containers/faq/layout-03";
import CaseStudy from "@containers/global/case-study";
import { caseStudiesData } from "@constants";
import ContactArea from "@containers/contact-us";
import BoxSection from "@components/BoxSection/layout-three/layout-3";
import QuoteForm from "@containers/translation-quote-form";
import UseScroll from "@containers/scroll";

const Footer = lazy(() => import("@layout/footer/layout-01"))

const VideoLocalizationPage = ({ location, data }) => {
  const [showRestComponents, setShowRestComponents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);
  UseScroll((scrollTop) => {
    if (scrollTop > 30) {
      setShowRestComponents(true);
    } else {
      setShowRestComponents(false);
    }
  }, setIsMobile);
  return (
    <Layout location={location}>
      <Seo
        title="Video Translation and Localization Services | Andovar"
        description="Innovative video localization and translation services designed to help you reach new markets and new customers quickly, accurately and cost-effectively."
        />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
   
      <main className="site-wrapper-reveal">
        <PageHeader data={content["video-page-header-section"]} />
        <QuoteForm />
        {
          isMobile && showRestComponents ? <>
            <PartnerArea data={content["video-localization-logo"]} />
          </> : !isMobile && <>
            <PartnerArea data={content["video-localization-logo"]} />
          </>
        }
        <FeatureGrid data={content["video-localization-features"]} />
           {
          showRestComponents && <>
        <IntroArea layout={2} data={content["video-localization-intro"]} />
        {/* <Slider3D data={content["video-localization-applications"]} /> */}
        <BoxSectionThree data={content["video-localization-solutions"]} />
        <ELPublishing data={content["video-tools"]} />
        <BoxSection layout={2} data={content["video-subtitling-section"]}/>
        <CaseStudy data={caseStudiesData} />
        <FaqArea data={content["video-faq-body"]} />
        <ContactArea Layout={2} />
        </>
      }
      </main>
      {
        showRestComponents && <Suspense fallback={<div></div>}>
          <Footer data={{ ...data.site.siteMetadata }} />
        </Suspense>
      }
    </Layout>
  );
};

export const query = graphql`
  query videoLocalizationPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "video-localization" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
  }
`;

VideoLocalizationPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default VideoLocalizationPage;
